/* --------------QA Ticketing---------------- */
/* --------Qa Dashboard Search (Shlok)------------- */
.firstCard16 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  width: 67px;
  text-align: left;
  float: right;
}
.LastCard86 {
  color: #000000;
  font-family: Lato;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  width: 84px;
  text-align: left;
}
.btnDownloadQuality {
  background-color: #2561a8;
  border-radius: 5.11px;
  width: 40px;
  height: 39px;
  margin-left: -40px;
  min-width: auto;
  cursor: pointer;
}
.downloadQualityCss {
  width: 12px;
  margin-left: -2px;
  margin-top: -5px;
}
.maincardQA {
  background-color: #ffffff;
  border-radius: 4px;
  width: auto;
  height: 1475px;
  margin-left: 14px;
  margin-right: 16px;
}
.quality-score-by-age {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-left: 10px;
}
.cardTextSpace {
  padding-left: 8px;
  padding-top: 30px;
}
.txtQA {
  padding-top: 8px !important;
  background: #e5e9ed url(/static/media/calendar-black.d0d589e5.svg) no-repeat
    right 15px center;
}
.btnSearchQa {
  background-color: #2561a8;
  border-radius: 4px;
  width: 144px;
  height: 43px;
  margin-left: 16px;
  margin-top: 44px;
  cursor: pointer;
}
.labelSearch {
  color: #ffffff;
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  text-transform: initial;
  cursor: pointer;
}
.rowMargin {
  padding-top: 25px;
}
.table-expandable-sctn2 {
  background: #e5e9ed;
}
.table-expandable-sctn2 button {
  cursor: pointer;
}
.table-expandable-sctn2 .nav-tabs .nav-link {
  padding-top: 20px;
  padding-bottom: 20px;
  background: transparent;
}
.table-expandable-sctn2 .nav-tabs {
  border-color: #dae2e5;
  position: relative;
}
.table-expandable-sctn2 input[type="text"] {
  width: 100%;
  border-radius: 4px;
  padding: 10px 50px 10px 20px;
  border: 0;
  color: #4a4a4a;
}
.table-expandable-sctn2 input[type="text"].no-bg {
  background: #fff none;
  padding: 10px 20px;
}
.table-expandable-sctn2 input::placeholder {
  font-size: 16px;
  padding: 0;
  color: #a7a7a7;
  font-weight: 400;
}
.table-expandable-sctn2 select {
  width: 100%;
  border-radius: 4px;
  padding: 10px 45px 10px 20px;
  border: 0;
  background: #fff url("./../Images//table-arr.png") no-repeat right 20px center;
  background-size: 13px;
  color: #a7a7a7;
  cursor: pointer;
}
.table-expandable-sctn2 .tab-content .row {
  padding: 20px 0;
  border-bottom: 1px solid #dae2e5;
}
.table-expandable-sctn2 .tab-content .row:last-child {
  border-bottom: 0;
}
.table-expandable-sctn2 button {
  background-color: #fff;
  margin-right: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.table-expandable-sctn2 button.btn-inv {
  color: #fff;
  background-color: #2561a8;
}
.table-expandable-sctn2 button.btn-dis {
  /* background-color: rgba(37, 97, 168, 0.4);
    border-color: rgba(37, 97, 168, 0.4);
    color: rgba(255, 255, 255, 0.4); */
  opacity: 0.4;
}
.table-expandable-sctn2 .tab-content button img {
  margin-right: 12px;
  top: -3px;
}
.table-expandable-sctn2 .tab-content button:last-child {
  margin-right: 0;
}
.table-expandable-sctn2 .tab-content .row.all-row {
  padding: 0;
  padding-top: 25px;
}
.table-expandable-sctn2 .tab-content .row.all-row div {
  margin-bottom: 25px;
}
.QAtable table thead tr {
  height: 60px;
  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee;
}
.btn-assignQa {
  background-color: #2561a8 !important;
  border: 1px solid #2561a8;
  border-radius: 4px;
  width: 121px;
  height: 43px;
}
.assign-lbl {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}
.assignbtnImg {
  width: 20px;
  height: 20px;
  margin-top: -5px;
}
.cSvBtn {
  background-color: #ffffff;
  border: 1px solid #2561a8;
  border-radius: 4px;
  width: 86px;
  height: 43px;
}
.QAtable input {
  display: none;
}
.searchlabel1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-left: 10px;
}
.rowSearch {
  border: none !important;
}
.rowSearch .viewSearch-btn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 185px;
  height: 43px;
  float: right;
  cursor: pointer;
  line-height: 0;
}
.view-searchLabl {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
/* .container-fluid .all-row{
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top:0px !important;
  } */
.txtQASearch {
  padding-top: 8px !important;
  background: #e5e9ed url(/static/media/calendar-black.d0d589e5.svg) no-repeat
    right 15px center;
  background-color: white;
}
/* ----------------End shlok css------------------- */
.qa-header {
  background-color: #ecf2f4;
  width: auto;
  height: 57px;
}
.qa-header .row {
  margin: 0;
}
.button-audit-done {
  background-color: #2561a8;
  border-radius: 4px;
  width: 154px;
  height: 43px;
  float: right;
  margin: 0 0 0 0;
  margin-top: 6px;
  /* margin-right: 25px; */
}
.button-audit-done-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

.qa-flex {
  padding: 13px;
}
.read-herdphone-icon {
  margin-left: 10px;
  width: 30px;
  height: 30px;
}

.header-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  padding-left: 5px;
}

.small-text-header {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  padding-left: 10px;
}

.QA-card-1 {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.03);
  width: 1246px;
  height: 159px;
  margin-left: 18px;
  margin-top: 15px;
}

.QA-card-2 {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 15px 0;
}
.QA-card-3 {
  background-color: #ffffff;
  border-radius: 4px;
}

.rectangle-box .row .col-md-3 .row {
  margin: 0px;
}

.removelinespace {
  margin-top: 15px;
  line-height: 100%;
}

.qa-textbox {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 31px;
  border: none;
  padding: 10px;
}
.msg-row .col-md-2 {
  padding: 0px;
}
.message-header .col-md-3 {
  padding: 0px;
}
.removerowmargin .row {
  margin: 0px;
}

.audit-details {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-left: 10px;
}

.note-icon {
  width: 22px;
  height: 22px;
}
.audit-line-2 {
  border: 0.5px solid #f1f1f1;
}

.margin-audit-detials {
  margin-left: 20px;
  margin-top: 16px;
}

.audit-oval-5 {
  background-color: #50e3c2;
  border-radius: 100%;
  width: 30px;
  height: 30px;
}

.ract-right-icon {
  width: 18px;
  height: 18px;
  margin-left: 6px;
  margin-top: 4px;
}

.qa-lead {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  margin-left: 13px;
  margin-bottom: 6px;
  margin-top: 22px;
}

.qa-detials-row {
  display: flex;
}
/* .down-icon {
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
  width: 15px;
  height: 10px;
  padding: 0;
} */

.name-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  margin-left: 10px;
  margin-top: 5px;
}
.newmargin .row {
  margin-left: 16px;
  margin-bottom: 20px;
}

.percentage {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 17px;
  font-weight: 900;
  line-height: 20px;
  text-align: left;
}

.calculate-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: auto;
  height: 43px;
  cursor: pointer;
}
.calculate-button-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.collap-head-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}
.card .card-header {
  border: none !important;
}

#QALeadList {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  left: 30%;
  width: 250px;

  background-color: #2561a8;
  border-radius: 4px;
  width: 154px;
  height: 43px;
  float: right;
  margin: 0 0 0 0;
  margin-top: 6px;
  /* margin-right: 25px; */
}
/* .button-audit-done-text{

    background-color: #F8E71C;

    border-radius: 100%;
    width: 30px;
    height: 30px
  } */
.ticket-title-text {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.ticket-title-text-p {
  color: #000000;
  font-family: Lato;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
}

.Black-headphone {
  margin-left: 4px;
  margin-top: 3px;
  width: 22px;
  height: 22px;
}
/* .bluedownarrow {
  width: 15px;
  height: 10px;
  padding: 0;
  cursor: pointer;
} */

.qa-flex {
  padding: 13px;
}
.read-herdphone-icon {
  margin-left: 10px;
  width: 30px;
  height: 30px;
}

.header-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  padding-left: 5px;
}

.small-text-header {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  padding-left: 10px;
}

.QA-card-1 {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.03);
  width: 1246px;
  height: 159px;
  margin-left: 18px;
  margin-top: 15px;
}

.QA-card-2 {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 15px 0;
}
.QA-card-3 {
  background-color: #ffffff;
  border-radius: 4px;
}

.rectangle-box .row .col-md-3 .row {
  margin: 0px;
}

.removelinespace {
  margin-top: 15px;
  line-height: 100%;
}

/* .qa-textbox {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 158px;
  height: 31px;
  border: none;
} */
.msg-row .col-md-2 {
  padding: 0px;
}
.message-header .col-md-3 {
  padding: 0px;
}
/* .removerowmargin .row{

  margin: 0px;
} */

.audit-score-box {
  background-color: #f3f3f3;
  text-align: right;
  padding: 5px 15px;
  margin: 0;
  margin-left: 35px;
}
.audit-details {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-left: 10px;
}

.note-icon {
  width: 22px;
  height: 22px;
}
.audit-line-2 {
  border: 0.5px solid #f1f1f1;
}

.margin-audit-detials {
  margin-left: 20px;
  margin-top: 16px;
}

.audit-oval-5 {
  background-color: #50e3c2;
  border-radius: 100%;
  width: 30px;
  height: 30px;
}

.ract-right-icon {
  width: 18px;
  height: 18px;
  margin-left: 6px;
  margin-top: 4px;
}

.qa-lead {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  margin-left: 13px;
  margin-bottom: 6px;
  margin-top: 22px;
}

.toggle-button-blue {
  /* background-color: #2561A8; */
  width: 31.03px;
  height: 21.98px;
  padding: 6px;
  padding-top: 8px;
  border-radius: 0px !important;
}
.down-icon {
  margin-left: 10px;
  width: 15px;
  height: 10px;
  margin-top: 10px;
}
.collapsebutton .toggle-button-blue {
  min-width: 0;
}
.collapsebutton .toggle-button-gray {
  min-width: 0;
}

.percentage {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 17px;
  font-weight: 900;
  line-height: 20px;
  text-align: left;
}

.calculate-button {
  background-color: #2561a8;
  border-radius: 4px;
  width: auto;
  height: 43px;
}
.calculate-button-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}
.collap-head-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}
.card .card-header {
  border: none !important;
}

#QALeadList {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  left: 30%;
  width: 250px;
}
#QALeadList-logout-ovrly {
  padding: 70px 10px 0 0;
  background-color: rgba(77, 92, 116, 0.2);
  position: fixed;
}
.QALeadListModal {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  /* border: 1px solid; */
  margin-top: 6px;
  width: 200px;
}
#QALeadListModal.styles_closeButton__20ID4 {
  display: none;
}

.QALeadList-Over-yellow {
  background-color: #f8e71c;

  border-radius: 100%;
  width: 30px;
  height: 30px;
}
.ticket-title-text {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.ticket-title-text-p {
  color: #000000;
  font-family: Lato;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
}

.Black-headphone {
  margin-left: 4px;
  margin-top: 3px;
  width: 22px;
  height: 22px;
}
.bluedownarrow {
  width: 15px;
  height: 10px;
}

.QA-card-2 .row .col-md-3 .row {
  float: right;
}
.message-content {
  margin-left: -19% !important;
  padding-top: 15px !important;
}

.v3-new-1 {
  border: 1px solid #dfdfdf;
  width: 110%;
  margin-top: 3.6%;
}

.today-02-new-1 {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  width: 100%;
  /* padding-left: 37px; */
}

.v4-new-1 {
  border: 1px solid #dfdfdf;
  width: 110%;
  margin-top: 3.6%;
  margin-left: -8.5%;
}
.up-1-1 {
  width: 15px;
  height: 10px;
  padding: 0;
  cursor: pointer;
  margin: 7px;
  margin-left: 57%;
}

.card-details-QA {
  background-color: #fafafa;
  width: 527px;
  height: 253px;
  margin-left: 270px;
}

.screen-shot-2018-05-03-at-2-29-59-pm {
  width: 433px;
  height: 216px;
  background: #fff;
  margin: auto;
  margin-top: 17px;
  padding: 20px;
}

.yesterday-02-new {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  width: 100%;
  /* padding-left: 23px; */
}
.v3-1-1 {
  border: 1px solid #dfdfdf;
  width: 110%;
  margin-top: 3.6%;
}
.v6-1-1 {
  border: 1px solid #dfdfdf;
  width: 110%;
  margin-top: 3.6%;
  margin-left: -8.5%;
}

.messages-04-new-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

.message-new-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}

.toggle-button-blue {
  /* background-color: #2561A8; */
  width: 31.03px;
  height: 21.98px;
  padding: 6px;
  padding-top: 8px;
  border-radius: 0px !important;
}
.toggle-button-gray {
  background-color: #e6e6e6;
  width: 31.03px;
  height: 21.98px;
  padding: 6px;
  padding-top: 8px;
  border-color: #e6e6e6;
  border-radius: 0px !important;
}
.collapsebutton .toggle-button-blue {
  min-width: 0;
}
.collapsebutton .toggle-button-gray {
  min-width: 0;
}

.yes-button-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 9px;
  font-weight: 700;
  line-height: 11px;
  width: 17.07px;
  text-align: left;
}

.no-button-text {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 9px;
  font-weight: 700;
  line-height: 11px;
  width: 15.52px;
  text-align: left;
}

.collapse-text-1 {
  color: #898989;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  width: auto;
  text-align: left;
}
.message-details-new {
  width: 85%;
  display: inline-block;
  padding-left: 9px;
}
.clip-icon-new {
  width: 22px;
  height: 22px;
  margin-left: 50px;
  vertical-align: top;
}
/* .card .card-block, .card .card-body {
padding: 5px 40px;
} */

.hi-diwakar-i-really1-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;

  text-align: left;
}

.message-content-new {
  margin-left: -19% !important;
  padding-top: 15px !important;
}

.hi-naman-please-hel-new {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  padding-left: 139px;
}

.collapsebutton .btn {
  padding: 3px 12px;
  border-radius: 0;
  font-size: 9px;
  /* margin-bottom: 5px; */
  transition: all 0.3s ease-in-out;
}
.yenowi {
  width: 55%;
}
.yenowi1 {
  width: 45%;
}
.collapdata .card-body {
  padding: 25px 12px;
}

.collapdata .card .card-header {
  background-color: transparent;
  border-bottom: 1px solid #f1f1f1;
  padding: 20px 0px;
  position: relative;
  cursor: pointer;
}
.btn-default.btn-on.active {
  background-color: #2561a8;
  color: #ffffff;
  border-radius: "0px";
}
.btn-default.btn-on {
  background-color: #e6e6e6;
  color: #9b9b9b;
  border-radius: "0px";
}

.btn-default.btn-off.active {
  background-color: #2561a8;
  color: #ffffff;
  border-radius: "0px";
}
.btn-default.btn-off {
  background-color: #e6e6e6;
  color: #9b9b9b;
  border-radius: "0px";
}

.chartstatuslive-details-div {
  padding-left: 30px;
  padding-top: 30px;
}

.chartstatuslive-details {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  width: 117px;
  text-align: left;
  display: table;
  padding-top: 10px;
}

.Chat-Satisfaction-chat {
  width: 535px;
  height: 94px;
  margin-left: 35px;
  margin-top: 50px;
}
.Chat-Satisfaction-chat-good {
  height: 94px;
  background-color: #3b92f5;
  display: inline-block;
  color: #ffffff;
}
.Chat-Satisfaction-chat-bad {
  background-color: #faed48;
  display: inline-block;
  height: 94px;
  color: #4a4a4a;
  text-align: right;
}
/* 
.chat-score-card .card-titel {
  width: auto;
} */
.Chat-Satisfaction-chat-detail {
  padding: 35px 30px;
  font-size: 16px;
}

.good-bad-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 14px;
  /* width: 63px;
  text-align: left; */
}
/* Css End */

/* My ticket button css start by kalpesh */
.table-ba {
  border-radius: 15px;
  width: 100px;
  display: inline-block;
  padding: 4px 10px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
}
.Qatable-blue-btn {
  background-color: #cbe0f1;
  border: 1px solid #0076ff;
  color: #1775e2;
  font-size: 12px;
}
.Qatable-green-btn {
  background-color: #cbf1e9;
  border: 1px solid #2d8f7b;
  color: #2d8f7b;
  font-size: 12px;
}
/* My ticket button css end */
/* Qa Hierar Master Css Start By Kalpesh */
.QahierarReact .ReactTable {
  border: none;
}
.QahierarReact {
  border-left: 1px solid rgb(0, 0, 0, 0.1);
  border-right: 1px solid rgb(0, 0, 0, 0.1);
}

.qacrmroles .ReactTable {
  border: none;
}
.qacrmroles {
  border-left: 1px solid rgb(0, 0, 0, 0.1);
  border-right: 1px solid rgb(0, 0, 0, 0.1);
}
/* Qa CrmRoles Css End By Kalpesh */
/* QA Reports page css Start by Shlok */
.qAReport .react-datepicker__navigation--previous {
  min-width: auto;
}
.qAReport .react-datepicker__navigation--next {
  min-width: auto;
}
.QAreports .ReactTable {
  border: none;
}
.QAreports .ReactTable .rt-tbody {
  background: white;
}
.QaModal input[type="text"] {
  width: 250px;
  height: 35px;
  border-radius: 4px;
  padding: 0 50px 0 20px;
  border: 0;
  background: #ecf2f4 url("./../Images/calendar-black.svg") no-repeat right 15px
    center;
  color: #4a4a4a;
}
.qAReport select {
  border-radius: 4px;
  width: 250px;
  height: 35px;
  padding-left: 8px;
  padding-right: 40px;
  border-color: #ecf2f4;
  background: #ecf2f4 url("./../Images//table-arr.png") no-repeat right 20px
    center;
  background-size: 13px;
  cursor: pointer;
  border: none;
  font-weight: 600;
}
.qAReport label {
  font-size: 13px;
  font-weight: 600;
  font-family: lato;
  text-align: left;
  line-height: 25px;
  display: block;
}
/* QA Reports page css End by Shlok */
/* Qa UserMaster css start Kalpesh */
.qausermasterreact .ReactTable {
  border: none;
}
.qausermasterreact .ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
  padding: 20px 10px !important;
}
.qausermasterreact {
  border-left: 1px solid rgb(0, 0, 0, 0.1);
  border-right: 1px solid rgb(0, 0, 0, 0.1);
}
/* Qa UserMaster Css End */
/* QA AuditParameters css start by Shlok */
.callTab-padding {
  padding: 80px 0px 60px;
  text-align: center;
}
.call-tab-lbl {
  font-size: 22px;
  font-weight: 700;
}
.addBtn-callTab {
  background-color: #2561a8;
  border-radius: 4px;
  width: 230px;
  height: 43px;
  cursor: pointer;
}
.call-lbl {
  font-size: 15px;
  font-weight: 600;
  display: block;
  margin-bottom: 2px;
  color: #595959;
}
.call-txt {
  background-color: #f3f3f3;
  border-radius: 2px;
  width: 215px;
  height: 43px;
  border: none;
  padding: 10px;
  color: black;
  font-weight: 500;
}
.call-tab-div-P{
  padding: 10px 5px 15px 25px;
}
.call-totalMarks{
  margin-top: 30px;
  float: left;
  margin-left: 10px;
  font-size: 15px;
  font-weight: 400;
}
.call-spn{
  font-size: 30px;
    font-weight: 500;
    color: #2561A8;
}
.Qa-audit table thead tr {
  background-color: #fff !important;
  border-bottom:none
}
.Qa-audit table tbody tr {
  border-bottom:none;
}
.Qa-audit table th {
  font-size: 13px;
  padding: 0px 35px;
}
.Qa-audit table td {
  font-size: 13px;
  color: #9b9b9b;
  padding: 7px 35px;
}
.dropdown-QA {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-right: 40px;
  background: #F3F3F3 url("../Images/dropDown.png") no-repeat right 12px center;
  border: none;
  cursor: pointer;
  width: 50px;
  margin-top: 20px;
}
.minuscircleCall {
  width: 20px;
  height: 20px;
  float: right;
  margin-right: 11px;
  margin-top: 12px;
  cursor: pointer;
}
.pluscircleCall {
  width: 20px;
  height: 20px;
  float: right;
  margin-right: -50px;
  margin-top: 12px;
  cursor: pointer;
}
.call-lbl-1 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 2px;
  color: #595959;
}
.switchBtn1{
  margin-top: -37px;
  margin-left: 90px;
}
.switchBtn2{
  float: right !important;
    margin-top: -37px;
    margin-right: 0px;
}
.call-btn-save{
  background-color: #2561a8;
  border-radius: 4px;
  width: 180px;
  height: 43px;
  cursor: pointer;
  float: right;
  margin-right: 45px;
  margin-top: 20px;
}
.butn-blue {
  background-color: #fff;
  color: #2561a8;
  font-size: 16px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
}
.call-twoCard{
  text-align: center;
  margin-top: 45%;
}
.QaAudit .module-tabs .tab-contentCall {
  padding: 22px 0px 0px;
  box-shadow: none;
  background: #F5F8F9;
}
.call-card-scrolling{
  height: 530px;
    overflow: auto;
    overflow-x: hidden;
}
.QaAuditScr ::-webkit-scrollbar {
  width: 10px;
    border-radius:8px;
}

.QaAuditScr ::-webkit-scrollbar-track {
  background: #f1f1f1; 
    border-radius:8px;
}
 
.QaAuditScr ::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius:8px;
}

.QaAuditScr ::-webkit-scrollbar-thumb:hover {
  background: #555;
    border-radius:8px;
}
.padqa {
  padding: 0 0 0 20px;
}
.TicketCategoyMasReact svg:not(:root).svg-inline--fa {
  overflow: visible;
  color:#a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
 }
 .qacrmroles svg:not(:root).svg-inline--fa {
  overflow: visible;
  color:#a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
 }
 .QAreports svg:not(:root).svg-inline--fa {
  overflow: visible;
  color:#a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
 }
 .qausermasterreact svg:not(:root).svg-inline--fa {
  overflow: visible;
  color:#a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
 }
 .QahierarReact svg:not(:root).svg-inline--fa {
  overflow: visible;
  color:#a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
 }
 .QaAudit .nav-tabs .nav-item {
   margin-bottom: 0;
 }
 .QaAudit .module-tabs .nav-tabs {
  border-bottom: 1px solid #ececec;
 }
 .QcFromDate1 .QcFromDate input[type=text] {
  width: 100%;
  border-radius: 4px;
  padding: 10px 50px 10px 10px;
  border: 0;
  background: #ecf2f4 url(/static/media/calendar-black.d0d589e5.svg) no-repeat right 15px center;
  color: #4a4a4a;
}
.QcFromDate .react-datepicker-wrapper {
  width: 100%;
}
.QcFromDate .react-datepicker__navigation--next {
  min-width: auto;
}
.QcFromDate .react-datepicker__navigation--previous {
  min-width: auto;
}
.resolutiondate input[type=text] {
  display: block;
  width: 100%;
  border-radius: 4px;
  padding: 10px 50px 10px 10px;
  border: 0;
  background: #fff url(/static/media/calendar-black.d0d589e5.svg) no-repeat right 15px center;
  color: #4a4a4a;
}
.resolutiondate .react-datepicker-wrapper{
  width: 100%;
}
.resolutiondate .react-datepicker__navigation--next {
  min-width: auto;
}
.resolutiondate .react-datepicker__navigation--previous {
  min-width: auto;
}
.table-expandable-sctn2 .tab-content .row.all-row .resolutiondate div {
  margin-bottom: 0;
}
/* QA AuditParameters css end by Shlok */
