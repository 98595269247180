/* Shlok css start */
/* Header */
.store-nav-tab3 {
  margin-top: -41px;
  margin-left: 77px;
  position: relative;
  z-index: 1;
}
.store-dashboardImg1 {
  width: 20px;
  height: 19px;
}
.store-myTicket {
  width: 18px;
  height: 17px;
  margin-right: 6px;
  margin-top: -2px;
}
.store-claim-logo {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-top: -2px;
}
.store-nav-tab1 {
  margin-top: -3%;
  margin-left: 27%;
  position: relative;
  z-index: 1;
}
.store-claim-space {
  margin-top: -2.9%;
  margin-left: 39%;
}
.storeHeader1 {
  width: 26px;
  height: 26px;
  margin-top: 13px;
  margin-left: 40px;
}
.store-chatImg {
  width: 25px;
  height: 25px;
  margin-top: -4px;
}
.store-setting {
  width: 24px;
  height: 24px;
}
.store-notifi {
  width: 24px;
  height: 24px;
}
.store-main-nav {
  margin-left: 18.5%;
}
.store-bitmap5 {
  width: 30px;
  height: 30px;
  margin-top: -25px;
  margin-left: 155px;
  background-color: #d8d8d8;
  border: 1px solid #979797;
  border-radius: 50%;
  font-size: 10px;
  line-height: 27px;
  text-align: center;
  cursor: pointer;
}
/* End header */
/* ***************************** */
/* Campaign tab css start */
.closebtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 68px;
  height: 31px;
  min-width: 68px !important;
  cursor: pointer;
}
.hdrcloselabel {
  color: #ffffff;
  font-family: Lato;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  text-transform: initial;
  cursor: pointer;
}
.store button {
  padding: 0px;
}
.table-cntr-card .card-body {
  padding: 0;
}
.table-cntr-card {
  height: 0;
}
.table-cntr-card table thead tr th {
  color: #989898;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  width: 113px;
  text-align: left;
}
.contactBtnGreen {
  background-color: #9cc541;
  border-radius: 4px;
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.5);
  width: 81px;
  height: 31px;
}
.notConnectedBtnRed {
  background-color: #c0505f;
  border-radius: 4px;
  box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.5);
  width: 105px;
  height: 31px;
  text-transform: initial;
}
.followUpBtnYellow {
  background-color: #f5a623;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 7px 0 rgba(0, 0, 0, 0.5);
  width: 78px;
  height: 31px;
  text-transform: initial;
}
.table-btnlabel {
  color: #ffffff;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  text-transform: initial;
}
.saveBtn {
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 4px;
  width: 59px;
  height: 31px;
}
.saveLabel {
  color: #2561a8;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  text-transform: initial;
}
.raisedticket-Btn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 101px;
  height: 31px;
  text-transform: initial;
}
.raise-ticketLbl {
  color: #ffffff;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.txtStore {
  padding-top: 0px !important;
  background: #e5e9ed url(/static/media/calendar-black.d0d589e5.svg) no-repeat
    right 15px center;
}
.dateTimeStore {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 183px;
  height: 31px;
  border: none;
  padding-left: 10px;
}
.responceDrop-down {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 190px;
  height: 31px;
  padding-left: 12px;
}
/* Compaign css End */
/* Shlok css end */
/* --------------------------------------------- */

/* chetan css starts */

/* store task starts */
.store-task-tabs ul {
  border-bottom: 0;
}
.store-task-tabs {
  background-color: #ecf2f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.store-task-tabs .nav-tabs .nav-link {
  font-weight: 400;
  padding: 17px 15px;
  font-size: 16px;
  font-weight: 600;
}
.store-task-tabs .nav-tabs .nav-link.active {
  font-weight: 700;
  border-bottom: 0;
  background-color: transparent;
}
.store-task-tabs button {
  font-size: 14px;
  padding: 10px 40px;
  margin-right: 15px;
}
.store-task-tab-cont {
  padding-right: 15px;
  padding-left: 15px;
}

.store-task-tab-cont table th {
  font-size: 12px;
  padding: 15px 25px;
}
/* store task ends */

/* store task view starts */
.store-task-view-top {
  padding: 10px 20px;
  background-color: #ecf2f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.str-img {
  width: 30px;
  height: 30px;
  background-color: #f8e71c;
  border-radius: 50%;
  overflow: hidden;
  line-height: 25px;
  text-align: center;
}
.str-img img {
  width: 18px;
}
.str-owner p {
  font-size: 14px;
  color: #000;
  margin-left: 10px;
  font-weight: 700;
}
.str-owner {
  display: flex;
  align-items: center;
}
.str-dtls select {
  background: #2561a8 url("./../Images/down-white.png") no-repeat right 20px
    center;
  border-radius: 4px;
  color: #fff;
  border: 0;
  font-size: 14px;
  padding: 11px 45px 11px 20px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-left: 25px;
  min-width: 205px;
}
.str-dtls select option {
  color: #2561a8;
  background-color: #fff;
  text-transform: initial;
  font-weight: 700;
}
.task-view-cntr {
  background-color: #fff;
  border-radius: 4px;
  padding: 25px 40px;
  height: 100%;
}
.task-view-cntr label {
  color: #9b9b9b;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
}
.task-view-cntr input,
.task-view-cntr select,
.task-view-cntr textarea {
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  border: 0;
  padding: 12px 15px;
  background-color: #ecf2f4;
  border-radius: 4px;
}
.task-view-cntr select {
  background: #ecf2f4 url("./../Images/down-1.png") no-repeat right 20px center;
  background-size: 18px;
  padding-right: 50px;
}
.task-view-cntr textarea {
  height: 150px;
  resize: none;
}
.task-view-cntr .row > div {
  padding-right: 10px;
  padding-left: 10px;
}
.task-view-cntr .row {
  margin-right: -10px;
  margin-left: -10px;
}
.store-cmnts {
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 15px 30px !important;
}
.store-cmnts::placeholder {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  color: #9b9b9b;
}
.add-cmnts p {
  color: #9b9b9b;
  font-weight: 700;
}
.add-cmnts a {
  color: #fff;
  background-color: #2561a8;
  border-radius: 4px;
  font-size: 14px;
  text-transform: uppercase;
  padding: 12px 25px;
  display: inline-block;
}
.add-cmnts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.cmnts span {
  color: #9b9b9b;
  font-size: 12px;
  font-weight: 700;
}
.cmnts p {
  color: #505050;
  font-size: 13px;
  font-weight: 700;
}
.cmnts-sctn {
  padding: 20px 0;
  border-bottom: 1px solid #e4e4e4;
  margin-top: 10px;
}
.outer-cmnts .cmnts-sctn:last-child {
  border-bottom: 0;
}
.task-view-right p {
  color: #4a4a4a;
  font-weight: 700;
  font-size: 16px;
}
.task-closure-progress {
  height: 10px;
  background-color: #ecf2f4;
  border-radius: 5px;
  margin-left: 12px;
}
.task-closure-progress .progress-bar {
  background: linear-gradient(to right, #f9c957 0%, #f66c1c 100%);
  box-shadow: none;
}
.task-closure .dte {
  white-space: nowrap;
  font-size: 15px;
  line-height: 1;
  margin-top: -2px;
}
.nos-days {
  font-size: 14px !important;
  text-align: right;
  width: 65%;
  position: relative;
  left: 17px;
  margin-top: 2px;
}
/* store task view ends */

/* chetan css ends */

/* ------------Shlok css---------------------------- */
/* ----------------Store Manager css--------------------- */
.table-expandable-sctn1 {
  background: #ecf2f4;
}
.table-expandable-sctn1 button {
  cursor: pointer;
}
.table-expandable-sctn1 .nav-tabs .nav-link {
  padding-top: 20px;
  padding-bottom: 20px;
  background: transparent;
}
.table-expandable-sctn1 .nav-tabs .nav-link.active {
  color: #2561a8;
  border-bottom: 2px solid #2561a8;
  box-shadow: none;
  /*background: #e3e9eb;*/
}
.table-expandable-sctn1 .nav-tabs {
  border-color: #dae2e5;
  position: relative;
}
.table-expandable-sctn1 input[type="text"] {
  width: 100%;
  border-radius: 4px;
  padding: 10px 50px 10px 20px;
  border: 0;
  color: #4a4a4a;
}
.table-expandable-sctn1 input[type="text"].no-bg {
  background: #fff none;
  padding: 10px 20px;
}
.table-expandable-sctn1 input::placeholder {
  font-size: 16px;
  padding: 0;
  color: #a7a7a7;
  font-weight: 400;
}
.table-expandable-sctn1 select {
  width: 100%;
  border-radius: 4px;
  padding: 10px 45px 10px 20px;
  border: 0;
  background: #fff url("./../Images//table-arr.png") no-repeat right 20px center;
  background-size: 13px;
  color: #a7a7a7;
}
.table-expandable-sctn1 .tab-content .row {
  padding: 20px 0;
  border-bottom: 1px solid #dae2e5;
}
.table-expandable-sctn1 .tab-content .row:last-child {
  border-bottom: 0;
}
.table-expandable-sctn1 button {
  background-color: #fff;
  margin-right: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.table-expandable-sctn1 button.btn-inv {
  color: #fff;
  background-color: #2561a8;
}
.table-expandable-sctn1 button.btn-dis {
  /* background-color: rgba(37, 97, 168, 0.4);
  border-color: rgba(37, 97, 168, 0.4);
  color: rgba(255, 255, 255, 0.4); */
  opacity: 0.4;
}
.table-expandable-sctn1 .tab-content button img {
  margin-right: 12px;
  top: -3px;
}
.table-expandable-sctn1 .tab-content button:last-child {
  margin-right: 0;
}
.table-expandable-sctn1 .tab-content .row.all-row {
  padding: 0;
  padding-top: 25px;
}
.table-expandable-sctn1 .tab-content .row.all-row div {
  margin-bottom: 25px;
}
.bill-graph-list {
  font-weight: 300;
  text-align: left;
  margin-left: 25px;
  margin-top: 30px;
}
.bill-graph-list li {
  padding: 4px 0;
  white-space: nowrap;
  display: block;
  font-size: 15px;
}
.bill-graph-list li b {
  font-weight: 700;
}
.tic-bill-graph .nv-x {
  font-size: 12px;
  font-weight: 700;
}
.tic-bill-graph .nv-y {
  font-size: 8px;
  color: #4e4e4e;
}
.tic-bill-graph .nv-chart,
.tic-bill-graph .nvd3-svg {
  width: 100%;
}
.apexcharts-toolbar {
  display: none !important;
}
.opendonutChart .apexcharts-canvas .position-right {
  right: 40px !important;
}
/* Task by ticket css start */
.taskByTable table th,
.taskByTable table td {
  font-size: 12px;
  padding: 20px 20px;
}
/* css end */
/* Edit Store Task page css start by Shlok */
.edit-storeTask-header {
  background-color: #ecf2f4;
  width: 100%;
  height: 57px;
  padding: 15px 20px 0px;
}
.store-header-lbl {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
}
.btnstore-last {
  display: block;
  float: right;
  margin-top: -8px;
}
.submitAs-reopen {
  background-color: #2561a8;
  border-radius: 4px;
  width: 210px;
  height: 43px;
  margin-left: 20px;
  cursor: pointer;
}
.store-Edit-lbl {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  margin-bottom: 7px;
}
.store-card-padding {
  padding: 20px 40px;
  margin-left: -15px;
  margin-top: 12px;
  margin-bottom: 12px;
  /* width: 780px; */
}
.store-edit-txt {
  background-color: #ecf2f4;
  border-radius: 4px;
  height: 43px;
  border: none;
  padding: 10px;
}
.store-mrg {
  margin-top: 20px;
}
.store-crmrole-Btn {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  padding: 3px 20px;
  border-radius: 5px;
  cursor: pointer;
  min-width: 10px;
}
.textarea-store {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 148px;
  resize: none;
  border: none;
  padding: 10px;
}
.textarea-store-comments {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 129px;
  resize: none;
  border: none;
  padding: 10px;
}
.butn-store {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
  /* height: 40px; */
  /* width: 155px; */
  float: right;
  text-transform: uppercase;
}
.store-mrg-1 {
  margin-top: 25px;
}
.store-hrLbl {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  float: right;
}
.naman-r-store {
  color: #000000;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-left: 6px;
  cursor: pointer;
}
.store-comment {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.store-cmt-comment {
  color: #505050;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  width: 100%;
}
.store-card-2 {
  padding: 20px 30px;
  /* margin-left: -60px; */
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  height: 69vw;
}
.store-card-3 {
  padding: 20px 30px;
  /* margin-left: -60px; */
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  height: auto;
}
.naman-r-store-2 {
  color: #000000;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.store-edit-data {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  width: 100%;
  text-align: left;
}
.store-edit-data-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-left: 7px;
}
.store-mrg-3 {
  margin-top: 25px;
}
.task-clouserDate {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  margin-top: 10px;
}
.store-date {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}
.progressbar-2 {
  width: 70%;
  margin-left: 6px;
  margin-bottom: 3px;
}
.progressbar-lbl {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  float: right;
  margin-right: 90px;
}
.store-hdrtMdal {
  padding: 7px 113px 10px 20px;
}
.modal-lbl {
  color: #2561a8;
  font-size: 15px;
  font-weight: 600;
}
.modal-lbl-1 {
  color: #16cba3;
  font-size: 15px;
  font-weight: 600;
}
.modal-lbl-2 {
  color: #696969;
  font-size: 15px;
  font-weight: 600;
}
#SubmitReopn-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  margin-right: 25px;
  margin-top: 45px;
  /* margin-bottom: 4px; */
  display: inline-block;
}
.storeImg-11 {
  height: 18px;
  width: 19px;
  margin: 7px;
}
.oval-5-1-new-store {
  background-color: #f8e71c;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: inline-block;
}
/*  css End edit Store Task*/
/* Store Task by tikcet css start by Shlok */
.store-header-task {
  margin-top: -20px;
  margin-left: -288px;
}
.headerBtn-store {
  margin-top: -41px;
  float: right;
  margin-right: 20px;
}
.raisedClaim-storeBtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 141px;
  height: 43px;
  margin-left: 20px;
  cursor: pointer;
}
.raisedClaim-lbl {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.btn-store-resolved {
  background-color: #2561a8;
  border-radius: 4px;
  height: 43px;
  margin-left: 15px;
  cursor: pointer;
}
.ImgBlue-lbl {
  width: 20px;
  height: 14px;
  padding: 3px;
  margin-left: 7px;
}
.noEditImg {
  height: 18px;
  width: 19px;
  margin-left: 660px;
  margin-top: -30px;
}
.noEditImg-1 {
  height: 18px;
  width: 19px;
  margin-left: 660px;
  margin-top: -252px;
}
.store-card-padding-ticket {
  padding: 20px 40px;
  margin-left: -15px;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 780px;
  height: 100vh;
}
.hrMargin {
  margin-top: 20px;
  margin-bottom: 15px;
}
.newtab {
  width: 100%;
  background: none;
  /* padding-top: 45px;
    padding-bottom: 15px; */
  padding-left: 0;
  padding-right: 0;
}
/*  Store Task by tikcet css end*/
/* Claim page by shlok */
.main-Claimdiv {
  padding: 5px 15px 15px;
}
.claimTbl table tr td:last-child,
table tr th:last-child {
  text-align: right;
  float: left;
}
.claim-headPhoneImg {
  width: 18px;
  height: 19px;
  margin-right: 7px;
}
.claimTbl .claim-span {
  font-size: 10px !important;
}
.claim-comment {
  color: #505050;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.claim-bataShoes {
  width: 350px;
  height: 197px;
  margin-left: 0px;
}
.col-md-10-card {
  margin-left: 5px;
  flex: 0 0 75%;
  max-width: 70%;
}
.claim-customer-padding {
  padding: 60px;
  padding-left: 6%;
  padding-top: 5px;
}
/* Claim page end */
/* StoreAddTask css start by kalpesh */
.card1 {
  width: 100%;
  padding: 42px 20px;
  margin-left: 0;
  margin-right: 0;
}
.store-Edit-lblcre {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 25px;
}
.store-Edit-lbl1 {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  margin-bottom: 7px;
}
.cardmargin {
  margin-left: -30px;
  height: 49.6vw;
}
/* StoreAddTask css end */
/* ClaimApproveReject css start for header button and bottom page by Kalpesh */
.claim-title1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 550;
  line-height: 22px;
  text-align: left;
  padding: 10px 0 0 0;
}
.btn-approrej {
  float: right;
  margin-top: 4px;
}
.btn-approrej1 {
  background-color: #2561a8;
  border-radius: 4px;
  border-color: #2561a8;
  width: 155px;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
  color: white;
  margin: 3px 0px 8px 12px;
}
.oval-approve {
  background-color: #f8e71c;
  border-radius: 100%;
  width: 31px;
  height: 31px;
  float: left;
  padding: 4px;
  margin: 9px 15px 9px 35px;
}
.card-radius2 b {
  font-size: 16px;
  font-weight: 700;
  line-height: 2;
}
.card-radius2 label {
  font-size: 16px;
  font-weight: 400;
}
.a {
  border-radius: 50%;
  background-color: #2561a8;
  color: white;
  padding: 3px 10px 5px 11px;
  font-weight: 500;
  margin-right: 10px;
}
.cardbor {
  border: 1px solid #e0e0e0 !important;
}
.alankrit {
  margin-bottom: 20px;
  display: grid;
}
/* ClaimApproveReject css end */
/* Raiseclaim search collapse css start kalpesh */
.Searchline {
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 0px;
}
.uploadsearch-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
}
.uploadsearchbtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 154px;
  height: 43px;
  cursor: pointer;
}
.uploadsearchbtn-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.searchdownload {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
}
.uploadsechmargin {
  margin-bottom: 15px;
}
.uploadsearch {
  text-align: center;
  margin-top: 70px;
}
.searchtext {
  background-color: #ffffff;
  border: 1px solid #979797;
  border-radius: 4px;
  width: 299px;
  height: 43px;
  float: right;
  padding: 10px;
}
.searchImg-raise {
  width: 20px;
  height: 20px;
  float: right;
  margin-right: -280px;
  margin-top: 12px;
}
.oderDetails-table .ReactTable .rt-thead.-header {
  background-color: #fff;
}
.orderdetailtext {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  margin-top: 10px;
}
/* Raiseclaim search collapse css start kalpesh */

/* Department-Master Css Start Kalpesh */
.deptMaster .ReactTable .rt-thead .rt-tr {
  font-size: 12px;
}
.deptMaster .ReactTable {
  border: none;
}
.right-sect-collapse .div-cntrdept {
  padding: 0 10px;
}
.editdeptpopover {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);
  padding: 25px 10px;
  /* margin-bottom: 25px; */
}
.editdeptpopover input,
.editdeptpopover select {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 250px;
  color: #000;
  display: block;
  border: 0;
  padding: 11px 15px;
  margin-top: 5px;
}
.delcan-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -25px;
}
/* Department-Master Css end */
/* SLA Template Department Start by Kalpesh */
.slatemp-text-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  display: inline-block;
  width: 33%;
}
.slatempreact .ReactTable {
  border-left: none;
  border-right: none;
}
.slatempreact {
  border-left: 1px solid rgb(0, 0, 0, 0.1);
  border-right: 1px solid rgb(0, 0, 0, 0.1);
}
/* SLA Template Department End */
/* Config add pop-up css start by Kalpesh */
.config-button {
  float: right;
  margin: 10px 38px 10px 0;
}
.config-buttontext {
  width: 140px;
  height: 40px;
  cursor: pointer;
  background-color: #2561a8;
  color: #fff;
}
.config-tab {
  width: 100%;
}
.cancelImg-config {
  float: right;
  width: 25px;
  height: 25px;
  margin-top: 15px;
  cursor: pointer;
}
.templateName {
  text-align: left;
  padding: 25px 0px 20px 55px;
}
.template-text {
  font-size: 20px;
  font-weight: 600;
  color: #2561a8;
  font-family: lato;
}
.template-editor {
  width: 92% !important;
  margin-left: 40px !important;
}
.template-editor .cke_bottom {
  display: none;
}
/* Config add pop-up css End by Kalpesh */
.stinlitab {
  display: inline-table;
}
.StoreRaiseReact .hope {
  display: block;
  font-size: 10px;
}
.camp-datepick .react-datepicker__navigation--previous {
  min-width: auto;
}
.camp-datepick .react-datepicker__navigation--next {
  min-width: auto;
}
.camp-datepick span {
  display: grid;
}
.reactstoreclaim .ReactTable {
  background-color: #f8f8f8;
}
.reacttableordermodal .ReactTable .rt-thead.-header {
  background-color: #f8f8f8 !important;
}
.raisereactTable svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
}
.storeplusline-12 {
  cursor: pointer;
  float: right;
  padding: 15px 0 0 0;
  height: 54px;
  pointer-events: none;
}
/* ----------------------------------------------- */
/* ------Change password btn css start-------- */
.userChangePW {
  text-align: center;
  font-size: 18px;
  color: #1555a2;
  cursor: pointer;
}
/* -------Change password btn css end------- */
